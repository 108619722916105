import request from '@/utils/request'

// 打卡活动列表
export function wishStatistics(params) {
  return request({
    url: `/v2/api/app/ap_member//member/wish/query_wish_count`,
    method: 'get',
    params
  })
}

// 心愿类型
export function wishStyle(params) {
  return request({
    url: `/v2/api/app/ad_user//wish/type/query_app_list`,
    method: 'get',
    params
  })
}

// 根据分类名称查心愿列表
export function wishPage(params) {
  return request({
    url: `/v2/api/app/ap_member//member/wish/query_page`,
    method: 'get',
    params
  })
}

// 心愿详情
export function wishInfo(params) {
  return request({
    url: `/v2/api/app/ap_member//member/wish/query_wish_info`,
    method: 'get',
    params
  })
}

// 获取资源方详情
export function resourceList(params) {
  return request({
    url: `/v2/api/app/ap_member//member/wish/query_resource_list`,
    method: 'get',
    params
  })
}

// 申请对接心愿
export function claimWish(data,params) {
  return request({
    url: `/v2/api/app/ap_member//member/wish/claim_wish`,
    method: 'post',
    data,
    params
  })
}

// 心愿类型
export function wishType(params) {
  return request({
    url: `/v2/api/app/ad_user//wish/type/query_app_list`,
    method: 'get',
    params
  })
}

// 会员的类型
export function memberType(params) {
  return request({
    url: `/v2/api/app/ap_member//wish/member/selectWishMemberTypeForAPP`,
    method: 'get',
    params
  })
}

// 提交心愿
export function wishAdd(data) {
  return request({
    url: `/v2/api/app/ap_member//member/wish/add`,
    method: 'post',
    data
  })
}

// 图片上传
export function uploadSimpleMedia(data,params) {
  return request({
    url: `/v2/api/app/ap_media//app/mediaManage/uploadSimpleMedia`,
    method: 'post',
    data,
    params
  })
}

// 我的心愿接口
export function myWishData(params) {
  return request({
    url: `/v2/api/app/ap_member//member/wish/query_my_wish_list`,
    method: 'get',
    params
  })
}

export default { wishStatistics,wishStyle,wishPage,wishInfo,resourceList,claimWish,wishType,memberType,wishAdd,uploadSimpleMedia,myWishData}