<template>
  <div class="detail titlesTop">
    <!-- <div class="head_jiantou">
      <div class="back iconfont icon-fanhui" @click="goback"></div>
      <div class="header">心愿详情</div>
    </div> -->
    <headTitle title="心愿详情"></headTitle>
    <div class="head_wrapper">
      <div class="head_icon">
        <img src="../../assets/images/xinyuan.png" alt="">
        <div>心愿发布</div>
      </div>
      <div class="head_title">
        <span>许愿人</span>
        <span>{{detailObj.memberName}}</span>
      </div>
      <div class="head_title">
        <span>手机号</span>
        <span>{{detailObj.phone}}</span>
      </div>
      <div class="head_title">
        <span>心愿类型</span>
        <span>{{detailObj.wishTypeName}}</span>
      </div>
      <div class="head_title">
        <span>心愿描述</span>
        <span>{{detailObj.description}}</span>
      </div>
      <div class="bottom_icon">
        <img src="../../assets/images/duijie.png" alt="">
        <div>申请对接企业</div>
      </div>
      <div>
        <div v-show="companyData.length > 0" class="detail_box" v-for="(item,index) in companyData" :key="index">
          <img class="detail_img" v-if="!item.pic || item.pic==='[]'" src="../../assets/images/company.png" alt="">
          <img class="detail_img" v-else :src="JSON.parse(item.pic)[0]" alt="">
          <div class="detail_right">
            <div>{{item.wishMemberName}}</div>
            <div>
              <span>{{item.description}}</span>
            </div>
          </div>
        </div>
        <div v-show="companyData.length === 0" class="empty_box">
          <img src="../../assets/images/emptyDetail.png" alt="">
        </div>
      </div>
      <div v-if="wishInfo && detailObj.status !== 3" class="empty_box">
        <div @click="openDialog">申请对接</div>
      </div>
      
    </div>
  </div>
</template>
<script>
import { Toast, Dialog } from "vant";
import wishApi from "@/api/wish";
export default {
  data() {
    return {
      detailObj: {},
      mainId: null,
      companyData:[], // 资源方详情
      applyShow: false, // 申请对接的弹窗
      wishInfo: null,
    };
  },
  created() {
    if(window.localStorage.enterpriseInfo&&window.localStorage.enterpriseInfo!='undefined'){ // 爱心企业登入
      this.wishInfo = JSON.parse(window.localStorage.enterpriseInfo);
    } else if(window.localStorage.personalInformation&&window.localStorage.personalInformation!='undefined'){  // 会员登入
      this.wishInfo = null;
    } else{ // 都没登入
      this.wishInfo = null;
    }

    const id = this.$route.query.id;
    this.mainId = id;
    this.getWishDetail();
    this.getCompany();
  },
  methods: {
    // 根据id获取心愿详情
    getWishDetail() {
      let params = { // app名字
        id: this.mainId
      }
      wishApi.wishInfo(params).then(res => {
        if (res.success) {
          this.detailObj = res.data;
        } else {
          Toast(`${res.message}`);
        }
      })
    },
    // 获取捐助的资源方详情 
    getCompany() {
      let params = { // app名字
        wishId: this.mainId
      }
      wishApi.resourceList(params).then(res => {
        console.log(res);
        if (res.success) {
          this.companyData = res.data;
        } else {
          Toast(`${res.message}`);
        }
      })
    },
    goback() {
      // const page = this.$route.query.page;
      this.$router.go(-1);
    },

    // 打开确认申请的弹窗 申请对接
    openDialog() {
      const wishInfo = JSON.parse(window.localStorage.enterpriseInfo);
      Dialog.confirm({
        title: '确认申请对接本次心愿吗？',
        message: '点击确认按钮确认申请',
      })
        .then(() => {
          let params = { // app名字
            wishId: this.detailObj.id,
            wishMemberId: wishInfo.id
          }
          wishApi.claimWish({},params).then(res => {
            if (res.success) {
              Toast('申请成功,等待管理员审核!');
              this.getCompany();
            } else {
              Toast(res.message);
            }
          });
          })
        .catch(() => {
          Toast('取消申请')
        });
      },
  }
};
</script>
<style lang="less">
.detail {
  .head_wrapper {
    padding: 5vw;
    .head_icon {
      display: flex;
      align-items: center;
      font-size: 0.34rem;
      img {
        width: 7vw;
        margin-right: 2vw;
      }
  }
  .head_title {
      display: flex;
      margin-top: 3vw;
      font-size: 0.32rem;
      span {
        &:nth-child(1) {
          width: 25vw;
          color: #8A8A8A;
        }
        &:nth-child(2) {
          width: 80vw;
        }
      }
    }
  .bottom_icon {
    display: flex;
    margin-top: 20vw;
    align-items: center;
    font-size: 0.34rem;
    img {
      width: 7vw;
      margin-right: 2vw;
    }
  }
  .empty_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 46vw;
    }
    div {
      width: 50vw;
      background: #1377e2;
      text-align: center;
      border-radius: 5px;
      height: 10vw;
      line-height: 10vw;
      color: #fff;
      font-size: 0.37rem;
      margin-top: 20px;
    }
  }
  .detail_box {
    display: flex;
    align-items: flex-start;
    padding: 3vw 0;
    .detail_img {
      width: 15vw; 
      height: 15vw;
      margin-right: 3vw;
    }
    .detail_right {
      width: calc(100% - 15px);
      div {
        &:nth-child(1) {
          // line-height: 10vw;
          font-size: 0.33rem;
        }
        &:nth-child(2) {
          display: flex;
          justify-content: space-between;
          color: #9A9A9A;
          font-size: 0.27rem;
        }
      }
      span {
        word-break: break-all;
      }
    }
  }
  .overlay_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    div {
      background: #fff;
    }
  }
  }
  .head_jiantou {
    position: relative;
    background: #cd0102;
    .back {
      position: absolute;
      width: 20px;
      height: 20px;
      color: #fff;
      text-align: center;
      left: 10px;
      top: 16px;
      line-height: 20px;
      font-size: 20px;
    }
    .header {
      height: 1rem;
      text-align: center;
      line-height: 1rem;
      font-size: 20px;
      color: #fff;
      letter-spacing: 2px;
    }
  }
  
}
</style>